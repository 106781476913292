(function ($) {
    
    /*
    
    Sorry for this shitty code, i had to tweak the hell of out it!
    
    */
    
    // Outer container
    var quizContainer     = $('#quiz');
    
    // Owl container
    var quizOwl           = quizContainer.find('.owl-carousel');
    
    // Done container
    var doneContainer     = quizContainer.find('#done-container');
    
    // Progressbar
    var quizProgressbar   = quizContainer.find('.progress-bar-inner');
    
    // Get counter-label
    var quizCounterLabel  = quizProgressbar.find('.progress-bar-inner-counter');
    
    // Current step label
    var quizCurrentLabel  = quizCounterLabel.find('span.current');
    
    // Total count label
    var quizTotalCount    = quizCounterLabel.find('span.total');
    
    // Slide count
    var quizSlideCount    = 0;
    
    // Current step
    var quizCurrentStep   = 1;
    
    // Array of quizfilter
    var quizFilter        = [];
    
    // Boolean value for quiz done
    var quizDone          = false;
    
    // Is video required to watch before going to next step?
    var quizRequireVideoEnd = true;
    
    // Autoplay videos when loaded (except for slide no 1)
    var quizAutoPlayVideo = true;
    
    // Ready event
    $(document).ready(function (){
        
        // Initialize quiz
        $().quiz();
        
    });
    
    // Initate quiz
    $.fn.quiz = function ()
    {
        
        // Check if quiz-container is present
        if(!quizContainer.length)
        {
            // We do not have a quiz
            return;
        }
        
        // Prevent navigation away from form
        window.onbeforeunload = function() {
            return quiz_general_strings.sure_quit;
        }
        
        // Init carousel
        quizOwl.owlCarousel({
            items      : 1,
            merge      : true,
            loop       : false,
            margin     : 10,
            video      : true,
            autoPlay   : false,
            lazyLoad   : false,
            center     : true,
            mouseDrag  : false,
            touchDrag  : false,
            smartSpeed : 0,
        });
        
        // Get filters
        // INFO: Filters are used if an answer affects the rest of the quiz-process.
        // The purpose is to skip any irrelevant slides based on the answers the user gives.
        var all_filters = $().getAllFilters();
        
        // Count all slides with filters includes
        var count_slides = $().countSlides(all_filters);
        
        // Set slide count in global var
        quizSlideCount = count_slides;
        
        // Set total slide count in DOM
        quizTotalCount.html(count_slides);
        
        // Set current step in DOM
        quizCurrentLabel.html(quizCurrentStep);
        
        // Display progressbar
        quizProgressbar.show();
        
        // Get current slide (first slide)
        var current_slide = $().currentSlide();
        
        // Load video on the first slide (only if video is present)
        $().loadVideo(current_slide);

        // Capture enter keypress and trigger next step
        $(document).keypress(function(e) {
            
            // Check if enter-key
            if(e.which === 13) {
                
                // Go to next step
                $().nextStep();
                
            }
            
        });

        // Next click-event
        quizContainer.find('.quiz-next').click(function(e) {
            
            // Validate and go to next step
            $(this).nextStep();
            
        });
        
        // Previous click-event
        quizContainer.find('.quiz-prev').click(function(e) {
            
            // Go back one step
            $().slideBack();
            
        });

    };
    
    /*** SLIDE HELPERS ***/
    
    // Get current slide
    $.fn.currentSlide = function()
    {
        // Get current slide
        return quizOwl.find('.owl-item.active');
    };
    
    // Slide back
    $.fn.slideBack = function ()
    {   
        // Slide back
        $().slide(true);
    };
    
    // Trigger slide
    $.fn.slide = function (backwards)
    {   
        
        // Don't allow sliding if quiz is done
        if(quizDone)
        {
            // No sliding allowed
            return;
        }
        
        // Check if going backwards
        var direction = ( (typeof backwards === 'undefined' || backwards === false) ? 'forward' : 'backward');

        // Get current slide
        var current_slide = $().currentSlide();
        
        // Check if we should update total slide count
        var should_update_count = (current_slide.children().first().data('has-filter') ? true : false);
        
        // Count quiz filters
        var quiz_filter_size = Object.size(quizFilter);
        
        // Unload video in current slide
        $().unloadVideo();
        
        // Check direction
        if(direction === 'backward')
        {

            // Abort if trying to move backwards when on first step
            if(quizCurrentStep <= 1)
            {
                return false;
            }
            
            // Check if we got filter
            if(quiz_filter_size > 0)
            {
                
                // Get previous slide
                var prev_element = current_slide.getPrevSlide();
                
                // Check that we got an element
                if(prev_element)
                {
                    // Go to previous element
                    quizOwl.trigger('to.owl.carousel', prev_element.index());
                }
                else
                {
                    console.log('Could not get previous slide-element');
                }
                
            }
            else
            {
                
                // Get previous slide
                var prev_element = current_slide.prev();

                // Slide to previous slide
                quizOwl.trigger('prev.owl.carousel');
                
            }
            
            // Load video in previous slide (only if video is present)
            $().loadVideo(prev_element);
            
            // Update counter
            $().updateStep('prev', should_update_count);

            // Check nav buttons display
            $().checkBackButton(next_element);
            
        } else if(direction === 'forward') {
            
            // Check if we got filter
            if(quiz_filter_size > 0)
            {
                
                // Get next slide
                var next_element = current_slide.getNextSlide();

                // Check that we got an element
                if(next_element)
                {
                    // Slide to next slide
                    quizOwl.trigger('to.owl.carousel', next_element.index());
                }
                else
                {
                    console.log('Could not get next slide-element');
                }

            }
            else
            {
                
                // Get next slide
                var next_element = current_slide.next();
                
                // Next slides
                quizOwl.trigger('next.owl.carousel');
                
            }
            
            // Load video in next slide (only if video is present)
            $().loadVideo(next_element);
            
            // Update counter
            $().updateStep('next', should_update_count);
            
            // Check nav buttons display
            $().checkBackButton(next_element);

        }
    };
    
    // Get the next slide elements with filters applied
    $.fn.getNextSlide = function()
    {
        
        // Build query based on filters
        var selector = $().buildQueryFromFilter(quizFilter);
        
        // Get all slides after the active one
        var next_elements = $(this).nextAll();
        
        // Loop through and find next element with filter included
        return next_elements.iterateSlides(selector);
        
    };
    
    // Get the previous slide elements with filters applied
    $.fn.getPrevSlide = function()
    {
        
        // Build query based on filters
        var selector = $().buildQueryFromFilter(quizFilter);
        
        // Get all slides before the active one
        var prev_elements = $(this).prevAll();
        
        // Loop through and find previous element with filter included
        return prev_elements.iterateSlides(selector);
        
    };

    // Loop forward/backwards in slides to find the desired slide-object.
    // Note that this also applies filters.
    $.fn.iterateSlides = function(selector)
    {
        
        // Set default value
        var found_slide = false;
        
        // Loop through all slides
        $(this).each(function(i, el){
            
            // Get element
            var obj = $(el);
            
            // Get child
            var child = obj.children().first();
            
            // Set default
            var has_filter = false;
            
            // Loop through filters
            $.each(quizFilter, function(i, el){
                
                // Check if this slide has a filter
                if(child.attr('data-filter-' + el.filter))
                {
                    // Set filter to true
                    has_filter = true;
                }
                
            });
            
            // Check if element has no filter or matches our filter
            if(!has_filter || child.is(selector))
            {   
                
                // Set slide
                found_slide = obj;
                
                // Stop each
                return false;

            }

        });
        
        // Could not find any slides
        return found_slide;
    };

    /*** BUTTON HELPERS ***/

    // Check if back-btn should be clickable
	$.fn.checkBackButton = function()
	{
        // Check if we are past step 1
        if(quizCurrentStep > 1)
        {
            // Display prev button
            $('.quiz-prev').prop('disabled', false);
        }
        else
        {
            // Hide prev button
            $('.quiz-prev').prop('disabled', true);
        }
        
    }
    
    /*** FILTER HELPERS ***/
    
    // Get all available filters in all of the slides slides
    $.fn.getAllFilters = function()
    {
        // Define var
        var filters = [];
        
        // Loop through slides to look for filters
        quizOwl.find('.owl-item').each(function(i, el){
            
            // Get element
            var obj = $(el);
            
            // Get first field
            var first_field = obj.find('input, textarea').first();
            
            // Check if we got a filter
            if(first_field.length)
            {
                // Check if has filter and is checked
                if(first_field.data('filter'))
                {
                    
                    // Get parameters
                    var filter = first_field.data('filter');
                    var value = first_field.data('filter-value');
                    
                    // Add filter to array
                    var new_object = {
                        filter: filter,
                        value: value
                    };
                    filters.push(new_object);
                    
                }
            }
            
        });
        
        return filters;
    };
    
    // Build jQuery-friendly selector-query for enqueing slides matching the filter
    $.fn.buildQueryFromFilter = function(filters)
    {
        // Define selector-string
        var selector = '';
        
        // Loop through filters
        $.each(filters, function(i, el){
            
            // Add to query-string
            selector = selector + '[data-filter-' + el.filter + '="' + el.value + '"]';
            
        });
        
        // Return selector
        return selector;
        
    };
    
    /*** VIDEO HELPERS ***/
    
    // Load the video in its iframe
    $.fn.loadVideo = function(slide)
    {
        
        // Get iframe
        var iframe = slide.find('iframe');

        // Check if we got a video
        if(iframe.length)
        {
            
            // Check if video already watched
            if(!slide.data('video-completed') && quizRequireVideoEnd === true)
            {

                // Make next button unavailable, video not watched
                $('.quiz-next').prop('disabled', true);
                
            }
            else
            {

                // Video is already watched, they can proceed
                $('.quiz-next').prop('disabled', false);
                
            }
            
            // Get source of iframe
            var src = iframe.data('src');
                
            // Get ID of iframe
            iframe_id = iframe.attr('id');

            // Create Froogaloop-player instance
            var player = $f(document.getElementById(iframe_id));

            // Add player ready-event
            player.addEvent('ready', function() {
                
                // Add player finish-event
                player.addEvent('finish', function(){
                    
                    // Mark slide as finished
                    slide.data('video-completed', true);
                    
                    // Make next button available
                    $('.quiz-next').prop('disabled', false);
                    
                });
                
                // If we are on step to or further, then autoplay videos
                if(quizCurrentStep > 1 && quizAutoPlayVideo)
                {
                    
                    // Wait one sec
                    setTimeout(function(){
                    
                        // Start playing
                        player.api('play');
                    
                    }, 1000);
                    
                }
                
            });

            // Set source
            iframe.attr('src', src);
            
            // Remove video-loaded class
            iframe.addClass('video-loaded');
            
            // Return succes
            return true;
            
        }
        else
        {
            // We dont have a video, they can click on next to validate and proceed to next slide
            $('.quiz-next').prop('disabled', false);
        }
        
        // No iframe was found
        return false;
        
    };
    
    // Unload video (to free memory)
    $.fn.unloadVideo = function()
    {
        
        // Get active slide
        var active_slide = $().currentSlide();
        
        // Get iframe in slider
        var iframe       = active_slide.find('iframe');

        // Check if we got an iframe
        if(iframe.length)
        { 
            // Clear source
            iframe.attr('src', '');
            
            // Remove video-loaded class
            iframe.removeClass('video-loaded');
            
        }
        
    };
    
    /*** STEP HELPERS ***/
    
    // Validate slide and proceed to the next step
    $.fn.nextStep = function()
    {
        
        // Get active slide
        var active_slide = $().currentSlide();
        
        // Default validation value
        var valid = true;
        
        // Get all fields in active slider
        var fields = active_slide.find('input, textarea');
        
        // Get iframe (if present)
        var iframe = active_slide.find('iframe');

        // Check if we got an iframe
        if(iframe.length)
        {
            
            // Get ID
            iframe_id = iframe.attr('id');
            
            // Get player
            var player = $f(document.getElementById(iframe_id));
            
            // Check that video has completed playing
            if(!active_slide.data('video-completed') && quizRequireVideoEnd === true)
            {
                // Stop if not completed watching video
                return;
            }
            
        }
        
        // Check if we got fields
        if(!fields.length)
        {
            // No fields to validate, moving on
            $().slide();
            
            // Stop execution
            return;
        }

        // Loop through fields
        fields.each(function ()
        {
            // Execute validation
            $(this).validate({
                errorTemplate: '<div class="error"><p><i class="fa fa-warning"></i> {output}</p></div>',
                errorMessages: {
                    required : quiz_general_strings.required,
                    correct  : quiz_general_strings.correct,
                    email    : quiz_general_strings.email,
                    numeric  : 'Must be numeric',
                    digits   : 'Must be 2 characters',
                    minlength: 'Must be atleast  % characters',
                    maxlength: 'Can be max  % characters',
                },
                onError      : function ()
                {
                    valid = false;
                }
            });
        });

        // Abort if not valid
        if(!valid)
        {
            // Validation did not succed
            return;
        }
        
        // Loop through fields to look for any filter to add, this will affect any further quiz-process
        fields.each(function ()
        {
            // Check if has filter and is checked
            if($(this).data('filter') && $(this).is(':checked'))
            {
                
                // Get parameters
                var filter = $(this).data('filter');
                var value  = $(this).data('filter-value');
                
                // Remove if already defined in filter
                quizFilter = $.map( quizFilter, function( val, i ) {
                    if(filter === val.filter)
                    {
                        return null;
                    }
                });
                
                // Add filter to global array
                var new_object = {
                    filter: filter,
                    value: value
                };
                quizFilter.push(new_object);
                
            }
            
        });
        
        // Check that this is the last slide (form submission)
        if( quizCurrentStep === quizSlideCount )
        {
            
            // Display loader
            quizContainer.find('.loader').removeClass('hidden');
            
            // Get form-element
            var form = quizContainer.find('form');
            
            // Generate certificate
            $.ajax({
                url      : ajaxurl,
                method   : 'post',
                dataType : 'json',
                data     : { action: 'quiz_done', data: form.serialize() }
            }).done(function (response) {
                
                // Remove loader
                quizContainer.find('.loader').addClass('hidden');

                // Check if success
                if(response.success)
                {
                    
                    // Update download link
                    doneContainer.find('.download-certificate').attr('href', '?download_token=' + response.token);
                    
                    // Set quiz as done
                    quizDone = true;
                    
                    // Hide form
                    form.hide();
                    
                    // Hide progress bar
                    quizContainer.find('.progress').hide();
                    
                    // Check if the certificate was sent to participant vja mail
                    if(response.mail_sent)
                    {
                        // Display mail-sent-test
                        doneContainer.find('.mail-sent-text').show();
                    }
                    
                    // Display done-container
                    doneContainer.show();
                    
                    // Clear onbeforeunload event
                    window.onbeforeunload = null;
                    
                    // Smooth scroll to 100px over the grats-message
                    $('html, body').animate({
                        scrollTop: (doneContainer.offset().top - 100)
                    }, 1000);
                    
                }
                else
                {
                    // Something went wrong
                    console.log('Something went wrong!');
                }
            });
            
        }
        else
        {
            // Go to next slide
            $().slide();
        }
    };
    
    // Update step in progressbar and global var
	$.fn.updateStep = function(direction, update_total)
	{
        // Next
		if(direction === 'next')
        {
            // Go next
            quizCurrentStep++;
            
        }
        // Prev
        else if(direction === 'prev')
        {
            // Go back
            quizCurrentStep--;

        }
        // Nope
        else
        {
            return;
        }
        
        // Check if should update total slide count.
        // This is used if we apply a filter and the total number of slides changes.
        if(update_total)
        {   
            
            // Count all slides with filters included
            var count_slides = $().countSlides(quizFilter);
            
            // Update total count in progressbar
            quizTotalCount.html(count_slides);
            
            // Update global var
            quizSlideCount = count_slides;
            
        }
        
        // Calculate progress bar width
        var quizProgress = ((quizCurrentStep - 1) * 100) / (quizSlideCount-1);
        
        // Update progressbar width
        quizProgressbar.css('width', quizProgress + '%');
        
        // Set current step
        quizCurrentLabel.html(quizCurrentStep);
        
        // Check counter offset
        $().checkCounterRight();
        
	};
    
    // Check if counter label who sticks with progress-bar should be indented from right side
    $.fn.checkCounterRight = function()
    {
        // Check if we have passed step 3
        if(quizCurrentStep > 3)
        {
            // No minus right on counter label
            quizCounterLabel.addClass('no-minus-right');
        }
        else
        {
            // Add minus right on counter label
            quizCounterLabel.removeClass('no-minus-right');
        }
    }
    
    // Count all slides based on first filter alternative in each "set-filter"-slide
    $.fn.countSlides = function (filters)
    {
        
        // Build query from filters
        var selector = $().buildQueryFromFilter(filters);
        
        // Define count
        var count = 0;
        
        // Loop through slides
        quizOwl.find('.owl-item').each(function(i, el){
            
            // Get element
            var obj = $(el);
            
            // Get child
            var child = obj.children().first();
            
            // Set default
            var has_filter = false;
            
            // Loop through filters
            $.each(filters, function(i, el){
                
                // Check if this slide has a filter
                if(child.attr('data-filter-' + el.filter))
                {
                    // Set filter to true
                    has_filter = true;
                }
                
            });
            
            // Check if element has no filter or matches our filter
            if(!has_filter || child.is(selector))
            {   
                // Add to count
                count++;
            }
            
        });
        
        // Update global count var
        quizSlideCount = count;
        
        // Return count
        return count;
        
    };
    
})(jQuery);

// Get the length of an object
Object.size = function(obj) {
    var size = 0, key;
    for (key in obj)
    {
        if (obj.hasOwnProperty(key))
        {
            size++;
        }
    }
    return size;
};