(function($) {

$.fn.initMap = function() {
    
    // Abort if not map-canvas is present in DOM
    if(!$('#map').length)
    {
        return;
    }
    
    // Define vars
    var map_canvas = $('#map');
    var parent_containter = $('#map-parent-container');
    var contact_item = $('.department').first();
    
    // Calculate inital height
    var inital_height = parent_containter.height() - contact_item.height();
    
    // Set inital height
    map_canvas.height(inital_height);
    
    // Specify features and elements to define styles.
    var styleArray = $.fn.getGoogleMapsStyling();
    
    // Default center of map
    var center = new google.maps.LatLng( map_canvas.data('lat'), map_canvas.data('lng') );
    
    // Get default zoom
    var inital_zoom = map_canvas.data('zoom');
    
    // Init map
    map = new google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: inital_zoom,
        styles: styleArray,
        streetViewControl: false,
        mapTypeControl: false
    });

    // Get markers
    var markers = $('.department');

    // Check if we have markers
    if(markers.length > 0)
    {
        // Add markers
        var bounds = new google.maps.LatLngBounds();
    
        // Loop through all markers
        markers.each(function(i,el){
        
            // Fetch vars
            var lat   = $(el).data('lat');
            var lng   = $(el).data('lng');
            var title = $(el).data('title');
        
            // Get pos
            var pos = new google.maps.LatLng( lat, lng);
        
            // Extend bounds
            bounds.extend(pos);
        
            // Add marker
            new google.maps.Marker({
                map: map,
                position: pos,
                title: title
            });
        
        });
    
        // Add hook on idle-event
        google.maps.event.addListenerOnce(map, 'idle', function() {
        
            // Resize canvas
            map_canvas.height(parent_containter.height());
        
            // Trigger resize to load new tiles
            google.maps.event.trigger(map,'resize');
        
            // Display map
            map_canvas.css('opacity', 1);
        
        });
    
        // Fit map to bounds
        map.fitBounds(bounds);
    }
    else
    {
        // Add hook on idle-event
        google.maps.event.addListenerOnce(map, 'idle', function() {
            
            // Resize canvas
            map_canvas.height(parent_containter.height());
            
            // Display map
            map_canvas.css('opacity', 1);
            
        });
    }
    
};

$.fn.getGoogleMapsStyling = function()
{
    // Specify features and elements to define styles.
    return [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ];
};

})(jQuery);