//add simple support for lazyloading background images:
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});


(function ($){

    var viewportWidth = $(window).width();

    $(document).ready(function(){

        // Focus on hidden contact form after showing it
        $('#collapseContact').on('shown.bs.collapse', function () {
          $('html, body').animate({
            scrollTop: $("#collapseContact").offset().top - 60
          }, 700);
        });

        // Init owl slider
        $('.background-slider .owl-carousel').initOwl();

        // Init search
        $.fn.search();

        // Feedback when signed up
        $.fn.newsFeedback();

        // Check that we got initMap-function
        if (typeof $.fn.initMap === 'function') {
            // Init map
            $.fn.initMap();
        }

        // Used in varslingsskjema to toggle fields
        $('.cf-toggle').each(function() {
            var toggle_container = $(this);
            var toggle_handle_id = toggle_container.data('toggle-handle');
            var toggle_handle = $('#' + toggle_handle_id);
            var wpcf7_form = toggle_container.closest('div[role="form"]');
            var wpcf7_id = wpcf7_form.attr('id');

            if (toggle_container.data('hidden-init')) {
              toggle_container.hide();
            }

            if(!toggle_handle.length) return;

            // Reset form fix
            $('#' + wpcf7_id).on('wpcf7:mailsent', function(){
                toggle_container.hide();
                toggle_container.find('input[type="text"]').val('');
                toggle_container.find('textarea').html('');
            });

            toggle_handle.on('change', function(){

                if(toggle_container.is(':visible'))
                {
                    toggle_container.hide();
                    toggle_container.find('input[type="text"]').val('');
                    toggle_container.find('textarea').html('');
                }
                else
                {
                    toggle_container.show();
                }

            });

        });


    });

    $.fn.initOwl = function()
    {
        
        var settings = {
            loop: true,
            nav: true,
            autoplay: true,
            mouseDrag: false,
            touchDrag: true,
            dots: false
        }

        // Disable carousel functionality if only one slide
        if ($(this).children().length <= 1) {
            $(this).addClass('owl-carousel--off');
            settings.loop = false;
            settings.nav = false;
            settings.autoplay = false;
            settings.touchDrag = false;
        }

        // Create global video el
        var prev_video_el = false;

        // Initiate own carouse
        $(this).owlCarousel({
            loop           : settings.loop,
            video          : true,
            margin         : 0,
            nav            : settings.nav,
            autoplay       : settings.autoplay,
            autoplayTimeout: 8000,
            mouseDrag      : settings.mouseDrag,
            touchDrag      : settings.touchDrag,
            items          : 1,
            dots           : settings.dots,
            lazyLoad       : true,
            animateOut     : 'fadeOut',
            animateIn      : 'fadeIn',
            navText        : ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],

            onInitialize : function () {

                // Check if mobile
                if(viewportWidth <= 991)
                {
                    // Remove all videos from items
                    $('.js-video').remove();
                }

            },

            // First item initalization
            onInitialized : function (property){

                // Vars
                var carousel      = $(property.target)
                var all_items     = carousel.find('.owl-item');
                var current_index = property.item.index;
                var current_el    = all_items.eq(current_index);
                var video_el      = current_el.find('.js-video');
                var has_video     = (video_el.length);

                // Check if we got an video in initial item
                if (has_video)
                {

                    // Play current video
                    video_el[0].play();

                    // Set this as previous video element for next item
                    prev_video_el = video_el[0];

                }
            },

            // Triggers on slide completed
            onChanged : function (property) {

                // Check if we got a previous video element
                if(prev_video_el)
                {

                    // Grab current previous element
                    var current_prev_video_el = prev_video_el;

                    // Wait for slide transition to finish
                    setTimeout(function(){

                        // Pause video
                        current_prev_video_el.pause();

                    }, 400);

                }

                // Vars
                var carousel      = $(property.target)
                var all_items     = carousel.find('.owl-item');
                var current_index = property.item.index;
                var current_el    = all_items.eq(current_index);
                var video_el      = current_el.find('.js-video');
                var has_video     = (video_el.length);


                // Check if we got an video in current item
                if (has_video)
                {

                    // Play current video
                    video_el[0].play();

                    // Set this as previous video element for next item
                    prev_video_el = video_el[0];

                }
                else
                {
                    // Next slide will not need to pause this video
                    prev_video_el = false;
                }

            }
        });
    };

    $.fn.newsFeedback = function()
    {
        // Get newsletter feedback el
        var el = $('.newsletter-feedback');

        // Check if exists
        if(el.length)
        {

            // Go to the first one
            var el = el.first();

            // Calculate scrolltop
            var scrolltop = (el.offset().top - 300);

            // Hide element
            el.hide();

            // Smooth scroll
            $('html, body').animate({
                scrollTop: scrolltop
            }, 1000);

            // Wait 1.2 seconds
            setTimeout(function(){

                // Display feedback
                el.fadeIn(500);

            }, 1200);

            // Wait 7 seconds
            setTimeout(function(){

                // Hide feedback
                el.fadeOut(500);

            }, 7000);

        }
    }

    $.fn.search = function ()
    {
        var btn = $('.search-btn, .search-trigger');
        var wrapper = $('.search-wrapper');
        var close = $('.search-close span');

        $('body').on('keyup', function (e)
        {
            if(e.keyCode === 27)
            {
                wrapper.removeClass('open');
            }
        });

        btn.on('click', function (e)
        {
            e.preventDefault();
            wrapper.addClass('open');
            wrapper.find('input').focus();
        });


        close.on('click', function ()
        {
            wrapper.removeClass('open');
        });

    };

    $(document).ready(function() {
      $('.gallery-type-slider').each(function() {
        var $this = $(this);
        var $id = $this.attr('data-id');
        var $controls = $this.attr('data-controls');
        var $nav = $this.attr('data-nav');
        var $description = $this.attr('data-description');
        var $customNav = '';

        if($nav == 'true') {
          $nav = true;
          $customNav = '.gallery-nav-slider-' + $id + ' a';
        } else {
          $nav = false;
        }

        if ($controls != 'thumbnails') {
          $controls = true;
        }

        $(this).owlCarousel({
          loop           : true,
          video          : true,
          margin         : 0,
          nav            : $nav,
          autoplay       : true,
          autoplayTimeout: 8000,
          mouseDrag      : true,
          touchDrag      : true,
          items          : 1,
          dots           : $controls,
          lazyLoad       : true,
          navText        : ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        });

      })
    });

})(jQuery);
