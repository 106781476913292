/**
 * @author Glenn Sjøstrøm
 * @version 1.0
 */
(function (factory) {
    if(typeof module === "object" && typeof module.exports === "object") {
        factory(require("jquery"), window, document);
    } else {
        factory(jQuery, window, document);
    }
}(function($, window, document, undefined) {
    function AllegroFormValidator(el, options) {
        this.el = el;
        this.errorTemplate = '<div class="error"><p><i class="fa fa-warning"></i> {output}</p></div>';

        this.errorMessages = {
            required: 'Is required',
            numeric: 'Need to be numeric',
            email: 'Email is not valid',
            minlength: 'Needs to be a min length of %s',
            maxlength: 'Can only be a length of %s',
            digits  : 'Needs to be a length of %s',
            digits_between: 'Needs to be a length between %s and %s',
            correct: 'Wrong, please try again',
        };

        if ( options !== undefined && options ) {
            if (options.errorTemplate) {
                this.errorTemplate = options.errorTemplate;
            }

            if (options.errorMessages) {
                for (var parentProp in this.errorMessages) {
                    for (var prop in options.errorMessages) {
                        if (parentProp === prop) {
                            this.errorMessages[parentProp] = options.errorMessages[prop];
                        }
                    }
                }
            }

            this.onError = options.onError ? options.onError : function(){};
            this.onSuccess = options.onSuccess ? options.onSuccess : function(){};
        }

        this.patterns = {
            params: /([^\:]+)/gi,
            rules: /[^\|]+/gi,
            numeric: /^\d+$/,
            email: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
            template: /{(.?)+}/
        };

        this.rules = [
            {
                name: 'correct',
                handle: function(context, val) {
                    if (context.el.closest('label').siblings().find('input').length) {
                        var choices = context.el.closest('label').siblings().find('input'),
                            valid = false;
                        choices.push(context.el[0]);
                        choices.each(function(i, el) {
                            if ($(el).data('correct') && $(el).is(':checked')) {
                                valid = true;
                            }
                        });

                        return valid;
                    }
                }
            },
            {
                name: 'required',
                handle: function(context, val) {
                    var type = context.el.attr('type');
                    if ( type === 'radio' || type === 'checkbox' ) {
                        // todo: Do you want to require a group, single or multiple fields?
                        if (context.el.closest('label').siblings().find('input').length) {
                            var choices = context.el.closest('label').siblings().find('input'),
                                valid = false;
                            choices.push(context.el[0]);
                            choices.each(function(i, el) {
                                if ($(el).is(':checked')) {
                                    valid = true;
                                }
                            });

                            return valid;
                        }

                        return $(el).prop('checked');
                    }
                    else if ( context.el.prop('tagName') === 'SELECT' ) {
                        return val;
                    } else if ( context.el.prop('tagName') === 'TEXTAREA' || context.el.prop('tagName') === 'INPUT' ) {
                        return $(context.el).hasClass('hidden') || $(context.el).parent().hasClass('hidden') ? true : (val ? true : false);
                    } else {
                        return false;
                    }
                }
            },
            {
                name: 'numeric',
                handle: function(context, val) {
                    return context.patterns.numeric.test(val);
                }
            },
            {
                name: 'email',
                handle: function(context, val) {
                    return val ? context.patterns.email.test(val) : true;
                }
            },
            {
                name: 'digits',
                handle: function(context, val, param) {
                    return val.length === +param;
                }
            },
            {
                name: 'digits_between',
                handle: function(context, val, param) {
                    var params = param.split(',');
                    return val.length >= params[0] && val.length <= params[1];
                }
            },
            {
                name: 'minlength',
                handle: function(context, val, param) {
                    return val.length >= +param;
                }
            },
            {
                name: 'maxlength',
                handle: function(context, val, param) {
                    return val.length <= +param;
                }
            }
        ];
    }

    AllegroFormValidator.prototype.validate = function() {
        var rules = this.el.data('rules') === undefined || ! this.el.data('rules') ? null : this.el.data('rules');

        if ( rules && typeof rules === 'string' ) {
            rules = rules.match(this.patterns.rules);
            if (rules.length) {
                this.valid = true;
                rules.forEach(function(rule) {
                    var ruleData = rule.match(this.patterns.params), ruleName = ruleData[0], ruleParam = ruleData[1];
                    if ( ! this.valid ) { return false; } else { this.hideError(); }

                    this.rules.forEach(function(rule) {
                        if ( rule.name === ruleName ) {
                            if ( ! rule.handle( this, this.el.val(), ruleParam ) ) {

                                if (this.onError) {
                                    this.onError(this.el);
                                }

                                this.showError(rule, ruleParam);
                                this.valid = false;
                            }
                        }
                    }, this);
                }, this);

                if (this.onSuccess && this.valid) {
                    this.onSuccess(this.el);
                }
            }
        }

        return this;
    };

    AllegroFormValidator.prototype.showError = function(rule, param) {
        var text = this.errorMessages[rule.name];
        if (/,/g.test(param)) {
            param = param.split(',');

            for (var i=0; i < param.length; i++ ) {
                text = text.replace( /%s/, param[i] );
            }
        }
        else if (/%s/g.test(text)) {
            text = text.replace(/%s/g, param);
        }

        var errorHook = this.el.closest('.error-hook');
        errorHook.find('.error').each(function() {
            $(this).remove();
        });

        errorHook.append(this.errorTemplate.replace(this.patterns.template, text));
    };

    AllegroFormValidator.prototype.hideError = function() {
        this.el.closest('.error-hook').find('.error').remove();
    };


    $.fn.validate = function(options) {
        return (new AllegroFormValidator(this, options)).validate();
    };
}));
