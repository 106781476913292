(function ($){
  function createCookie(name, value, days) {
      var expiration_date = new Date();
      expiration_date.setFullYear(expiration_date.getFullYear() + days);

      var expires = "; expires=" + expiration_date.toUTCString();
      document.cookie = name + "=" + value + expires + "; path=/;";
  }

  function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return "";
  }

  function eraseCookie(name) {
      createCookie(name, "", -1);
  }

  function checkCookie(name) {
      var check = document.cookie.indexOf(name);

      if (check == -1 && name == 'history') {
        $('.js-cookie').addClass('is-active');
        $('button.js-cookie-close').focus();
      } else if ( check == -1 ) {
          // If no cookie exists: create cookie
          createCookie(name, null, 1);
      }
  }

  $(document).ready(function() {
      // Initial history cookie check
      checkCookie("history");

      $('.js-cookie a, .js-cookie button').on('click', function() {
        createCookie('history', null, 1);
        $('.js-cookie').removeClass('is-active');
      })
  });
})(jQuery);
