(function ($){
    
    $(document).ready(function(){

        // Newsfeed livereload
        $.fn.newsfeedLivereload();
        
    });
    
    $.fn.newsfeedLivereload = function()
    {
        // Get section   
        var section = $('.newsfeed-section');
        
        // Get container
        var container = section.find('.livereload');
        
        // Time interval in seconds
        var interval = 15;
        
        // Check if exists
        if(container.length)
        {
            
            // Get last updated-container
            var last_update = $('.livereload-last-update');
            
            // Get loader
            var loader = last_update.find('.loader');
            
            // Display loader
            loader.show();
            
            // Get news items
            $.ajax({
                url      : ajaxurl,
                dataType : 'json',
                data     : {action: 'get_newsfeed_items'}
            }).done(function(response) {
                
                // Check if we got items
                if(response.result)
                {
                    
                    // Update content in container
                    container.html(response.html);
                    
                    // Update last updated datetime
                    last_update.find('span').html(response.last_updated);
                    
                    // Display last updated-container
                    last_update.show();
                    
                    // Hide loader
                    loader.hide();
                    
                    // Check if container is hidden
                    if(section.is(':hidden'))
                    {
                        // Display container
                        section.slideDown();
                    }
                    
                }
                else
                {
                    
                    // Hide last updated-container
                    last_update.hide();
                    
                    // Update content in container
                    container.html(response.html);
                    
                    // Hide loader
                    loader.hide();
                    
                }
                
                // Wait before trigger reload again
                setTimeout(function(){
                    
                    // Trigger reload
                    $.fn.newsfeedLivereload();
                    
                }, (interval * 1000) );
                
            });
            
        }
        
    };
    
})(jQuery);